import React from 'react';

import './UpcomingShows.css';
import { Item } from '../../components';
import { images, data } from '../../constants';

/*

<table className="app__shows-calendar">
                <tbody className="app__shows-event-container">
                    <tr className="app__shows-event">
                        <td className="app__shows-location">
                            <div className="app__show-location">
                                <h1 className='app__show-locname'>San Diego, CA</h1>
                            </div>
                            <div className="app__show-theatre">
                                <p className="app__show-theatname">Atlanta Black Theatre Festival</p>
                                <p className="app__show-theatname-sub">Southwest Arts Center - Main Stage</p>
                            </div>
                        </td>
                        <td className="app__shows-dates">
                            <p>August 31st, 4:00pm EDT</p>
                        </td>
                        <td className="app__shows-ticket-container">
                            <a className="app__shows-ticket" href="https://abtf.ludus.com/index.php" target="_blank" rel="noopener noreferrer">Order Tickets</a>
                        </td>
                    </tr>
                </tbody>
            </table> 

*/

const UpcomingShows = () => (
    <div className="app__bg app__shows" id="shows" var={{padding:"2rem"}}>
        <div className="app__shows-title flex__center">
            <h1 className="headtext__cormorant">Upcoming Shows</h1>
        </div>
        <div>
            <table className="app__shows-calendar">
                <tbody className="app__shows-event-container">
                    <tr className="app__shows-event">
                        <td className="app__shows-location">
                            <div className="app__show-location">
                                <h1 className='app__show-locname'>Roanoke, VA</h1>
                            </div>
                            <div className="app__show-theatre">
                                <p className="app__show-theatname">Mill Mountain Theatre</p>
                            </div>
                        </td>
                        <td className="app__shows-dates">
                            <p>February 6th, 6:30pm EST</p>
                            <p>February 7th, 6:30pm EST</p>
                            <p>February 8th, 2:00pm EST</p>
                            <p>February 8th, 7:30pm EST</p>
                            <p>February 9th, 2:00pm EST</p>
                        </td>
                        <td className="app__shows-ticket-container">
                            <a className="app__shows-ticket" href="https://ci.ovationtix.com/36191/production/1214014" target="_blank" rel="noopener noreferrer">Order Tickets</a>
                        </td>
                    </tr>
                </tbody>
            </table> 

            <table className="app__shows-calendar">
                <tbody className="app__shows-event-container">
                    <tr className="app__shows-event">
                        <td className="app__shows-location">
                            <div className="app__show-location">
                                <h1 className='app__show-locname'>The Villages, FL</h1>
                            </div>
                            <div className="app__show-theatre">
                                <p className="app__show-theatname">New Covenant United Methodist Church</p>
                            </div>
                        </td>
                        <td className="app__shows-dates">
                            <p>February 16th, 7:00pm EST</p>
                        </td>
                        <td className="app__shows-ticket-container">
                            <a className="app__shows-ticket" href="https://www.tix.com/ticket-sales/NCUMC/7095/event/1415225" target="_blank" rel="noopener noreferrer">Order Tickets</a>
                        </td>
                    </tr>
                </tbody>
            </table> 
            
        </div>
        
    </div>
);

export default UpcomingShows;